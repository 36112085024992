var httpPath = window.location.origin

var envPath = ''
var kqPath = ''
var qrcodeUser = ''
var qrcodeServer = ''

getEnvPath(httpPath)

function getEnvPath(httpPath) {
  var ret1 = /tqd\.fuqim\.com/
  var ret2 = /check\.fuqim\.com/
  var ret3 = /www\.fuqimao\.com/
  var ret4 = /sp\.fuqim\./
  var ret5 = /train\.fuqim\./
  var ret6 = /suser\.fuqim\.com/
  if (ret1.test(httpPath)) {
    envPath = 'http://192.168.110.176:8081/filemanage/uploadForm'
    kqPath = 'https://www.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = 'https://package.fuqim.com/images/icon/server-code.png'
  } else if (ret2.test(httpPath)) {
    envPath = 'http://192.168.110.176:8081/filemanage/uploadForm'
    kqPath = 'https://sandbox.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = 'https://package.fuqim.com/images/icon/server-code.png'
  } else if (ret3.test(httpPath)) {
    envPath = 'https://file.fuqim.com/filemanage/uploadForm'
    kqPath = 'https://www.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = '/images/qrcode_server_1.png'
  } else if (ret4.test(httpPath) || ret6.test(httpPath)) {
    console.log('envPath111', envPath)
    envPath = 'https://sfile.fuqim.com/filemanage/uploadForm'
    kqPath = 'https://www.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = 'https://package.fuqim.com/images/icon/server-code.png'
  } else if (ret5.test(httpPath)) {
    envPath = 'https://efile.fuqim.com/filemanage/uploadForm'
    kqPath = 'https://www.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = 'https://package.fuqim.com/images/icon/server-code.png'
  } else {

    envPath = 'https://sfile.fuqim.com/filemanage/uploadForm'
    kqPath = 'https://sandbox.99bill.com/gateway/recvMerchantInfoAction.htm'
    qrcodeUser = '/images/qrcode_user_1.png'
    // qrcodeServer = 'https://package.fuqim.com/images/icon/server-code.png'
    // console.log('envPath222', envPath)
  }
  envPath = 'http://192.168.102.100:8084/filemanage/uploadForm'
}
